.header {
  position: absolute;
  z-index: 1;
}

.brady {
  margin-top: -3rem;
}

.hi {
  font-family: "Ink Free";
  margin-left: 5rem;
}

.welcome {
  font-family: "Ink Free";
  margin-left: 20rem;
  margin-top: -2.4rem;
}

@media screen and (max-width: 480px) {
  .brady {
    width: 60%;
    margin-top: 1px;
  }
  .hi {
    margin-left: 5px;
    margin-bottom: 1px;
  }
  .welcome {
    margin-left: 4rem;
    margin-top: 1px;
  }
}

@media screen and (max-height: 450px) {
  .brady {
    width: 30%;
    margin-top: 1px;
  }
  .hi {
    margin-left: 5px;
    margin-bottom: 1px;
    font-size: small;
  }
  .welcome {
    margin-left: 4rem;
    margin-top: 1px;
    font-size: small;
  }
}
