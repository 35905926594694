.toolTip {
  font-family: "Ink Free";
  text-align: center;
  font-weight: bold;
  color: orangered;
  background: rgba(204, 204, 204, 0.7);
  border-radius: 5px;
  padding: 0.5rem;
  transform: translate3d(12px, 0px, 0px);
}

.toolTipContact {
  font-family: "Ink Free";
  text-align: center;
  font-weight: bold;
  color: orangered;
  background: rgba(204, 204, 204, 0.7);
  border-radius: 5px;
  padding: 0.5rem;
  transform: translate3d(20px, 15px, 0px);
}

.toolTipWorkExperience {
  font-family: "Ink Free";
  text-align: center;
  font-weight: bold;
  color: orangered;
  background: rgba(204, 204, 204, 0.7);
  border-radius: 5px;
  padding: 0.5rem;
  transform: translate3d(60px, -35px, 0px);
}
