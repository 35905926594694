.skillsHeader {
  vertical-align: top;
  text-align: left;
  margin-top: 0;
  padding-bottom: 20px;
  font-family: "Ink Free";
  font-weight: bold;
  display: flex;
  font-size: xx-large;
}

@media screen and (max-width: 480px) {
  .skillsHeader {
    padding-bottom: 0px;
    font-size: large;
  }
  .overallDIV {
    overflow: scroll;
    height: 70vh;
    width: 45vw;
  }
}

@media screen and (max-height: 849px) {
  .skillsHeader {
    padding-bottom: 0px;
  }
  .overallDIV {
    overflow: auto;
    height: 70vh;
    width: 35vw;
  }
}
