.degree {
  font-style: italic;
}

.info {
  display: inline-block;
}

.educationLogo {
  display: inline-block;
  margin: 0;
  margin-right: 1rem;
}

.educationLogoCC {
  display: inline-block;
  margin: 0;
  margin-right: 1rem;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.padHelper {
  padding: 0;
  margin: 0;
}

.topSpacer {
  padding-top: 150px;
}

.educationHeader {
  vertical-align: top;
  margin-top: 0;
  padding-bottom: 70px;
  font-family: "Ink Free";
  font-weight: bold;
  display: flex;
  font-size: xx-large;
}

@media screen and (max-width: 480px) {
  .educationHeader {
    padding-bottom: 0px;
    font-size: large;
  }
  .topSpacer {
    padding-top: 45px;
  }
}

@media screen and (max-height: 849px) {
  .educationHeader {
    padding-top: 0px;
    padding-bottom: 50px;
  }
  .topSpacer {
    padding-top: 45px;
  }
  .info {
    max-width: 300px;
  }
  .overallEducation {
    overflow: auto;
    max-height: 460px;
  }
}

@media screen and (max-height: 450px) {
  .educationHeader {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .topSpacer {
    padding-top: 45px;
  }
  .overallEducation {
    overflow: scroll;
    max-height: 260px;
  }
}
