.workHistoryHeader {
  vertical-align: top;
  text-align: left;
  margin-top: 0;
  padding-bottom: 70px;
  font-family: "Ink Free";
  font-weight: bold;
  display: flex;
  font-size: xx-large;
}

.companyLogo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.workExperienceh3 {
  text-align: center;
  font-style: italic;
  margin-top: 0;
  margin-bottom: 50px;
}

.workExperienceh4 {
  text-align: center;
  margin-top: 0;
  padding: 0;
  margin: 0;
}

.workExperienceDates {
  text-align: center;
  font-style: italic;
  margin-top: 0;
}

.bottomTextWE {
  font-style: italic;
  text-align: right;
  font-size: smaller;
}

.colWE {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.rowWE {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

@media screen and (max-width: 480px) {
  .mainSection {
    overflow: scroll;
    height: 70vh;
    width: 60vw;
  }
  .workHistoryHeader {
    padding-bottom: 0px;
    padding-top: 0px;
    font-size: large;
    text-align: center;
  }
  .workExperienceh3 {
    margin-bottom: 5px;
  }
}

@media screen and (max-height: 849px) {
  .workHistoryHeader {
    padding-bottom: 0px;
    padding-top: 25px;
  }
  .bottomTextWE {
    margin-top: -20px;
  }
}

@media screen and (max-height: 770px) {
  .mainSection {
    overflow: auto;
    max-height: 510px;
  }
  .workHistoryHeader {
    padding-bottom: 0px;
    padding-top: 25px;
    font-size: x-large;
  }
  .bottomTextWE {
    margin-top: 0px;
  }
}

@media screen and (max-height: 450px) {
  .mainSection {
    overflow: scroll;
    max-height: 300px;
  }
  .workHistoryHeader {
    padding-bottom: 0px;
    padding-top: 0px;
    font-size: larger;
  }
  .bottomTextWE {
    margin-top: 30px;
    margin-bottom: 0px;
  }
}
