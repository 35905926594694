.paragraph {
  font-family: "Ink Free";
  width: 50%;
  font-size: 1.7rem;
  text-align: left;
  left: 70%;
  top: 46%;
  position: fixed;
  transform: translate(-50%, -50%);
}

.canvas {
  left: 23%;
  top: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  height: 60vh;
  width: 30rem;
}

@media screen and (max-width: 950px) {
  .canvas {
    visibility: hidden;
  }
  .overallAboutMe {
    overflow: scroll;
    width: 90vw;
    height: 70vh;
  }
  .paragraph {
    font-size: xx-large;
    top: 0;
    left: 0;
    transform: none;
    width: 100%;
    position: inherit;
  }
}

@media screen and (max-width: 450px) {
  .canvas {
    visibility: hidden;
  }
  .overallAboutMe {
    overflow: scroll;
    width: 90vw;
    height: 70vh;
  }
  .paragraph {
    width: 90%;
    font-size: 1rem;
    text-align: left;

    position: fixed;
    transform: translate(-70%, -50%);
  }
}

@media screen and (max-width: 1400px) {
  .canvas {
    width: 20rem;
  }
  .overallAboutMe {
    overflow: scroll;
    width: 90vw;
    height: 70vh;
  }
  .paragraph {
    font-size: large;
    top: 48%;
    left: 68%;
  }
}

@media screen and (max-height: 800px) {
  .overallAboutMe {
    overflow: scroll;
    width: 90vw;
    height: 70vh;
  }
  .paragraph {
    font-size: larger;
    top: 40%;
    left: 72%;
  }
}

@media screen and (max-height: 450px) {
  .paragraph {
    font-size: small;
  }
}
