input[type="text"],
select,
textarea {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */
  border: 1px solid #ccc; /* Gray border */
  border-radius: 4px; /* Rounded borders */
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
}

input[type="email"],
select,
textarea {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */
  border: 1px solid #ccc; /* Gray border */
  border-radius: 4px; /* Rounded borders */
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
}

/* Style the submit button with a specific background color etc */
.btn {
  background-color: #ecb228;
  color: black;
  padding: 12px 20px;
  border: 1;
  border-color: black;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Ink Free";
  font-weight: bold;
  font-size: large;
  margin-top: 15px;
}

label {
  font-family: "Ink Free";
  font-weight: bold;
}

/* When moving the mouse over the submit button, add a darker green color */
.btn:hover {
  background-color: #45a049;
}

.contactHeader {
  font-family: "Ink Free";
  font-weight: bold;
  display: flex;
  font-size: xx-large;
}

@media screen and (max-height: 850px) {
  .btn {
    height: 45px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

@media screen and (max-height: 450px) {
  .overallContactMe {
    overflow: scroll;
    max-height: 270px;
  }
  .contactHeader {
    font-size: x-large;
    margin-top: 0px;
    margin-bottom: 10px;
  }
}
