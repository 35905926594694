.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.modal {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  padding: 5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  background-color: rgb(230, 228, 228);
  z-index: 11;
  width: 80vw;
  height: 70vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.closeButton {
  display: inline-block;
  margin: 2px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  position: absolute;
  right: 0;
  top: 0;
  border-color: grey;
  border-radius: 14px;
  width: 2rem;
  height: 2rem;
}

@media screen and (max-width: 480px) {
}
